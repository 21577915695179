import { gql } from '@apollo/client';
import {
  bookTitleCardFragment,
  originalBookTitleCardFragment,
} from '../../shared/fragments/books';
import { characterCardFragment } from '../../shared/fragments/character';
import { labelFragment } from '../../shared/fragments/label';
import { liveBlockFragment } from '../../shared/fragments/lives';
import { pageInfoFragment } from '../../shared/fragments/pageInfo';
import {
  curationBlockFragment,
  featureBlockFragment,
  titleCardFragment,
} from '../../shared/fragments/videos';

export const GET_LEAD_TITLE = gql`
  query cosmo_leadTitle {
    webfront_leadTitle {
      leadCode
      titleCode
      text
      thumbnail {
        small
        large
      }
    }
  }
`;

export const GET_UPSELL = gql`
  query cosmo_upsell {
    webfront_upsell {
      canDisplay
    }
  }
`;
export const GET_UPSELL_GUIDE = gql`
  query cosmo_upsell_guide {
    webfront_upsell {
      guide {
        destinationUrl
        subscriptionDisabled {
          code
        }
      }
    }
  }
`;

export const GET_HOME = gql`
  query cosmo_home($infoCheck: Boolean) {
    webfront_infoList(isCheck: $infoCheck) {
      title
      url
      ttl
    }
    labelBlock {
      name
      labelList {
        ...Label
      }
    }
  }
  ${labelFragment}
`;

export const GET_GENRE = gql`
  query cosmo_genre(
    $genreId: ID!
    $page: Int
    $pageSize: Int
    $titlesPerBlock: Int!
    $livesPerBlock: Int!
  ) {
    getSakuhinGroupBlock(genreCode: $genreId) {
      ...CharacterCard
    }
    webfront_linearChannels(genreId: $genreId) {
      id
      name
      description
      thumbnail {
        standard
      }
    }
    leagueBlock(genreCode: $genreId) {
      name
      list {
        name
        code
        tagCode
        thumbnail {
          standard
        }
      }
    }
    liveBlocks(genreCode: $genreId, numPerBlock: $livesPerBlock) {
      blockList {
        ...LiveBlock
      }
    }
    webfront_recommendBlocks(
      genreId: $genreId
      page: $page
      pageSize: $pageSize
      sakuhinsPerFeature: $titlesPerBlock
    ) {
      recommendBlocks {
        id
        featureName
        featureTypeCode
        featurePickupFlag
        leadText
        titleResults
        titles {
          ...TitleCard
          thumbnail {
            standard
            secondary
          }
        }
        titleComments {
          titleCode
          comment
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${pageInfoFragment}
  ${titleCardFragment}
  ${liveBlockFragment}
  ${characterCardFragment}
`;

export const GET_USER_BLOCKS = gql`
  query cosmo_getUserBlocks(
    $favoriteTitlesSortOrder: PortalSortOrder!
    $titlesPerBlock: Int!
    $volumeGroupType: BookVolumeGroupTypeCode = ALL
  ) {
    webfront_watchingEpisodes(episodeSize: $titlesPerBlock) {
      id
      episodeName
      episodeTitleInfo {
        id
        name
      }
      displayNo
      thumbnail {
        standard
      }
      interruption
      duration
      completeFlag
    }
    webfront_favoriteTitles(
      pageSize: $titlesPerBlock
      sortOrder: $favoriteTitlesSortOrder
    ) {
      titles {
        ...TitleCard
      }
      pageInfo {
        ...PageInfo
      }
    }
    bookHistory: bookReadMore(
      pageSize: $titlesPerBlock
      volumeGroupType: $volumeGroupType
    ) {
      books {
        ...BookTitleCard
        book {
          bookContent {
            mainBookFile {
              resumePoint
              completeFlg
            }
          }
        }
      }
    }
    recommendedBooks: webfront_bookUserRecommended(
      page: 1
      pageSize: $titlesPerBlock
      tagCode: "HOME"
    ) {
      results
      books {
        ...BookTitleCard
      }
      heading
      comment
    }
  }
  ${pageInfoFragment}
  ${titleCardFragment}
  ${bookTitleCardFragment}
`;

export const GET_BOOK_GENRE = gql`
  query cosmo_bookRecommendBlocks(
    $genreId: ID!
    $page: Int
    $pageSize: Int
    $titlesPerBlock: Int!
  ) {
    bookRecommendBlocks: webfront_bookRecommendBlocks(
      genreId: $genreId
      page: $page
      pageSize: $pageSize
      booksPerBlock: $titlesPerBlock
    ) {
      pageInfo {
        ...PageInfo
      }
      recommendBlocks {
        __typename
        ... on BookSpotlightBlock {
          type
          code
          spotlightList {
            name
            code
            linkTypeCode
            thumbnail {
              secondary
            }
            sakuhin {
              name
              code
            }
            feature {
              name
              code
            }
            featureTemplate {
              name
              code
            }
          }
        }
        ... on BookRankingBlock {
          type
          code
          trackingCode
          books {
            ...BookTitleCard
          }
          rankings {
            rankDiff
            isNewRanking
          }
          results
          targets {
            code
            displayName
            books {
              ...BookTitleCard
            }
            rankings {
              rankDiff
              isNewRanking
            }
          }
        }
        ... on BookFeatureBlock {
          type
          code
          trackingCode
          feature {
            name
            code
            comment
            typeCode
            displayEnd
            books {
              ...BookTitleCard
            }
            results
          }
        }
        ... on BookTemplateBlock {
          type
          code
          trackingCode
          template {
            heading
            displayEnd
            code
            typeCode
            url
            lead
            name
            featureArtwork {
              thumbnail {
                standard
              }
            }
          }
        }
        ... on BookOriginalBlock {
          type
          code
          results
          leadTitle
          books {
            ...OriginalBookTitleCard
          }
        }
        ... on OriginalComicBlock {
          type
          code
          results
          leadTitle
          books {
            ...OriginalBookTitleCard
          }
        }
        ... on BookUserRecommendedBlock {
          type
          code
          results
          books {
            ...BookTitleCard
          }
          heading
          comment
        }
        ... on BookBlock {
          type
          code
          books {
            ...BookTitleCard
          }
          results
        }
        ... on MagazineBlock {
          type
          code
          name
          results
          magazineGenreCode
          trackingCode
          books {
            ...BookTitleCard
          }
        }
      }
    }
  }
  ${pageInfoFragment}
  ${bookTitleCardFragment}
  ${originalBookTitleCardFragment}
`;

export const GET_BOOK_CATEGORY_BLOCK = gql`
  query cosmo_getBookCategoryBlock($genreId: ID!) {
    bookSearchCategoryBlock(genreId: $genreId) {
      type
      code
      name
      menuList {
        name
        code
        defaultSortOrder
        typeCode
      }
    }
  }
`;

export const GET_VIDEO_RECOMMEND_BLOCKS = gql`
  query cosmo_videoRecommendBlocks(
    $genreCode: ID!
    $page: Int!
    $pageSize: Int!
    $numPerBlock: Int!
  ) {
    videoRecommendBlocks(
      genreCode: $genreCode
      page: $page
      pageSize: $pageSize
      numberOfContentPerBlock: $numPerBlock
    ) {
      blocks {
        ... on VideoRecommendLiveBlock {
          __typename
          code
          type
          trackingCode
          block {
            ...LiveBlock
          }
        }
        ... on VideoRecommendTitleBlock {
          __typename
          ...CurationBlock
        }
        ... on VideoRecommendFeatureBlock {
          __typename
          code
          type
          trackingCode
          block {
            ...FeatureBlock
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${pageInfoFragment}
  ${curationBlockFragment}
  ${liveBlockFragment}
  ${featureBlockFragment}
`;
