import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import Icon, { Icons } from '../../../shared/components/Common/Icon';
import useWindowSize from '../../../shared/hooks/useWindowSize';
import { DEVICE } from '../../../shared/styles';

const Layout = styled.div`
  position: relative;
  margin-top: 16px;

  @media ${DEVICE.mobileWide} {
    margin-top: 14px;
  }
`;

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  overflow-x: auto;
  scroll-behavior: smooth;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ItemsContainer = styled.div`
  width: 100%;
`;

const ButtonSafeArea = styled.div<{ direction: 'left' | 'right' }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 48px;
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.15s ease-in;

  ${({ direction }) =>
    direction === 'right'
      ? css`
          right: 0;
        `
      : css`
          left: 0;
          transform: scale(-1);
        `};

  ${Layout}:hover &,
  ${Layout}:focus-within & {
    opacity: 1;
  }

  @media (pointer: coarse) {
    /* タッチ対応デバイスでは非表示 */
    display: none;
  }
`;

const NavButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  border-radius: 50% 0 0 50%;
  background-color: ${({ theme }) => theme.button.sliderNav.background};
  box-shadow: 0px 4px 8px 0px rgba(43, 38, 71, 0.1);
  backdrop-filter: blur(8px);
  transition: background-color 0.15s ease-in;

  color: ${({ theme }) => theme.button.sliderNav.text};
  cursor: pointer;

  div {
    /* Icon style */
    width: 20px;
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.button.sliderNav.backgroundHover};
  }
`;

const Slider: React.FC<PropsWithChildren> = ({ children }) => {
  const { width } = useWindowSize();

  const scrollerRef = useRef<HTMLDivElement>(null);

  const [showNavigation, setShowNavigation] = useState({
    left: false,
    right: false,
  });

  const changeNavigationVisibility = useCallback(() => {
    const hasMoreLeft = (scrollerRef.current?.scrollLeft ?? 0) > 0;
    const hasMoreRight =
      !!scrollerRef.current &&
      scrollerRef.current.scrollLeft + scrollerRef.current.clientWidth <
        scrollerRef.current.scrollWidth;
    setShowNavigation({ left: hasMoreLeft, right: hasMoreRight });
  }, []);

  const clickToScroll = useCallback((direction: 'right' | 'left') => {
    const content = scrollerRef.current;
    if (content) {
      const offset = content.clientWidth * 0.75;
      content.scrollBy({
        left: direction === 'right' ? offset : -offset,
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    if (scrollerRef.current) {
      changeNavigationVisibility();
    }
  }, [changeNavigationVisibility, width]);

  const ScrollButton: React.FC<{ direction: 'right' | 'left' }> = ({
    direction,
  }) => {
    return (
      <ButtonSafeArea direction={direction}>
        <NavButton onClick={() => clickToScroll(direction)}>
          <Icon name={Icons.HALF_ARROW_RIGHT} />
        </NavButton>
      </ButtonSafeArea>
    );
  };

  return (
    <Layout>
      <SliderContainer ref={scrollerRef} onScroll={changeNavigationVisibility}>
        <ItemsContainer>{children}</ItemsContainer>
      </SliderContainer>
      {showNavigation.left && <ScrollButton direction="left" />}
      {showNavigation.right && <ScrollButton direction="right" />}
    </Layout>
  );
};

export default Slider;
