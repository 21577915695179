import Image from 'next/legacy/image';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { DEVICE } from '../../../styles';
import { COLORS } from '../../../styles/theme';
import Icon, { Icons } from '../../Common/Icon';
import {
  BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE,
  BLOCK_VIEW_TITLE_CARD_SIZE,
} from '../constants';

type ReadMoreCardType = 'video' | 'book' | 'rankingBook' | 'live';

const messages = defineMessages({
  showAll: {
    id: 'readMoreCard.showAll',
    defaultMessage: 'すべて表示',
  },
});

const Container = styled.div<{ type: ReadMoreCardType }>`
  position: relative;
  width: ${({ type }) => BLOCK_VIEW_TITLE_CARD_SIZE[type].width}px;
  height: ${({ type }) => BLOCK_VIEW_TITLE_CARD_SIZE[type].height}px;

  @media ${DEVICE.mobileWide} {
    width: ${({ type }) => BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE[type].width}px;
    height: ${({ type }) => BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE[type].height}px;
  }
`;

const Content = styled.div<{ type: ReadMoreCardType }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${COLORS.white_10};
  box-sizing: border-box;
  &:hover > div {
    transform: scale(1.1);
  }

  ${({ type }) =>
    type !== 'book' &&
    css`
      border-radius: 10px;
      overflow: hidden;
    `}
`;

const ReadText = styled.div`
  font-weight: 600;
  font-size: 18px;
  @media ${DEVICE.mobileWide} {
    font-size: 16px;
  }
`;

const ResultText = styled.div`
  font-size: 14px;
  color: ${COLORS.white_75};
  @media ${DEVICE.mobileWide} {
    font-size: 13px;
  }
`;

const Layout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.4;
  transition: all 0.15s ease-in;
`;

const StyledIcon = styled(Icon)`
  width: 36px;
  height: 36px;
`;

interface ReadMoreCardProps {
  titleNumText?: string;
  type: ReadMoreCardType;
  onClick?: () => void;
}

const ReadMoreCard: React.FC<ReadMoreCardProps> = ({
  titleNumText,
  type,
  onClick,
}) => {
  const intl = useIntl();
  const bgImage =
    type === 'video'
      ? '/images/video-pattern.svg'
      : type === 'book' || type === 'rankingBook'
      ? '/images/book-pattern.svg'
      : '/images/live-pattern.svg';

  return (
    <Container type={type} onClick={onClick}>
      <Content type={type}>
        <Image src={bgImage} alt="" layout="fill" objectFit="cover" />
        <Layout>
          <StyledIcon name={Icons.FULL_ARROW_RIGHT} />
          <ReadText> {intl.formatMessage(messages.showAll)}</ReadText>
          {titleNumText && <ResultText>{titleNumText}</ResultText>}
        </Layout>
      </Content>
    </Container>
  );
};

export default ReadMoreCard;
