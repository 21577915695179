import Link from 'next/link';
import React, { HTMLAttributes, useState } from 'react';
import styled, { css } from 'styled-components';
import { DEVICE } from '../../styles';

interface TabProps {
  isActive: boolean;
  hasOnAirLive?: boolean;
}

const commonTabCss = css<TabProps>`
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  height: 40px;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.tabs.text};
  text-align: center;
  line-height: 40px;
  vertical-align: middle;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  transition: all 0.15s ease-in;

  ${({ isActive, theme }) =>
    isActive &&
    css`
      color: ${theme.tabs.textHovered};
      font-weight: 600;
      border-bottom: 2px solid ${theme.tabs.textHovered};
    `};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.tabs.textHovered};
  }

  @media ${DEVICE.mobileWide} {
    height: 32px;
    line-height: 32px;
  }
`;

const TabLink = styled.a<TabProps>`
  ${commonTabCss};
`;

const TabButton = styled.button<TabProps>`
  ${commonTabCss};
  ${({ hasOnAirLive: hasStreamingLive }) =>
    hasStreamingLive &&
    css`
      &::after {
        content: '';
        top: 4px;
        right: -10px;
        position: absolute;
        margin: auto;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: ${({ theme }) => theme.liveTitleCard.liveNowIconActive};
      }
    `}
`;

const TabWrapper = styled.div`
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  padding: 0 16px 0 0;

  @media ${DEVICE.mobileWide} {
    padding: 0 14px 0 0;
  }

  &:not(:first-child) {
    padding-left: 16px;

    // divider
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 1.4em;
      width: 1px;
      background-color: ${({ theme }) => theme.tabs.divider};
    }

    @media ${DEVICE.mobileWide} {
      padding-left: 14px;
    }
  }
`;

const ScrollShader = styled.div<{ shadeColor?: string }>`
  position: relative;
  width: 100%;

  // Hide right edge with this gradation to give users a hint to suggest that this is scrollable
  :before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 32px;
    background: linear-gradient(
      90deg,
      transparent,
      ${({ shadeColor, theme }) =>
        shadeColor ?? `var(--tabsShadeColor,${theme.background.default})`}
    );
    z-index: 1;
    pointer-events: none;
  }
`;

const Scroller = styled.div`
  white-space: nowrap;
  overflow: auto hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  padding-right: 32px;
  font-size: 16px;
  @media ${DEVICE.mobileWide} {
    font-size: 13px;
  }
`;

interface TabsProps extends HTMLAttributes<HTMLElement> {
  tabs: TabInfo[];
  defaultActiveIndex?: number;
  onTabItemClick?: (index: number) => void;
  tabComponentName?: string;
}

interface TabInfo {
  text: string;
  href?: string;
  hasOnAirLive?: boolean;
}

interface StatelessTabsProps extends TabsProps {
  activeIndex: number;
  setActiveIndex?: (num: number) => void;
  tabComponentName?: string;
  shadeColor?: string;
}

const Tabs: React.FC<TabsProps> = ({
  tabComponentName,
  defaultActiveIndex = 0,
  ...props
}) => {
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex);
  return (
    <StatelessTabs
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      {...props}
      tabComponentName={tabComponentName}
    />
  );
};

export const StatelessTabs: React.FC<StatelessTabsProps> = ({
  activeIndex,
  setActiveIndex,
  tabs,
  onTabItemClick,
  tabComponentName,
  shadeColor,
  ...props
}) => {
  const clickAction = (index: number) => {
    setActiveIndex && setActiveIndex(index);
    if (typeof onTabItemClick === 'function') {
      onTabItemClick(index);
    }
  };

  return (
    <ScrollShader shadeColor={shadeColor}>
      <Scroller {...props} data-ucn={tabComponentName}>
        {tabs.some((tab) => tab.href !== undefined)
          ? tabs.map((tab: TabInfo, index: number) => (
              <TabWrapper key={tab.text}>
                <Link href={tab.href ?? ''} passHref legacyBehavior>
                  <TabLink
                    onClick={(e) => {
                      e.stopPropagation();
                      clickAction(index);
                    }}
                    isActive={index === activeIndex}
                    data-ucn="tab-link"
                  >
                    {tab.text}
                  </TabLink>
                </Link>
              </TabWrapper>
            ))
          : tabs.map((tab: TabInfo, index: number) => (
              <TabWrapper key={tab.text}>
                <TabButton
                  key={tab.text}
                  onClick={(e) => {
                    e.stopPropagation();
                    clickAction(index);
                  }}
                  isActive={index === activeIndex}
                  hasOnAirLive={tab.hasOnAirLive}
                  data-ucn="tab-btn"
                >
                  {tab.text}
                </TabButton>
              </TabWrapper>
            ))}
      </Scroller>
    </ScrollShader>
  );
};

export default Tabs;
