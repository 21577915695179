import Link, { LinkProps } from 'next/link';
import { FC } from 'react';
import styled from 'styled-components';
import {
  cosmo_all_labelQuery,
  cosmo_getBookCategoryBlockQuery,
  cosmo_getBookCategoryBlockQueryVariables,
  cosmo_getSearchGenresQuery,
} from '../../../__generated__/globalTypes';
import Icon, { Icons } from '../../../shared/components/Common/Icon';
import {
  BLOCK_PADDING,
  BLOCK_PADDING_MOBILE,
} from '../../../shared/components/Slider';
import { UrlQueryParams } from '../../../shared/constants';
import useClientQuery from '../../../shared/hooks/useClientQuery';
import { DEVICE } from '../../../shared/styles';
import { GET_ALL_LABEL, GET_SEARCH_GENRES } from '../../freeword/gql';
import type { BookGenreTopBookCategorySelectorGenreButtonLog } from '../../Log/__types__/bookGenreTop-bookCategorySelector-genreButton';
import { BOOK_CATEGORY_SELECTOR_GENRE_BUTTON } from '../../Log/ComponentName/bookGenreTop';
import { getKafkaClient } from '../../Log/kafkaClient';
import { GenreUrlPrefix } from '../constants';
import { GET_BOOK_CATEGORY_BLOCK } from '../gql';
import CategoryListSlider from './CategoryListSlider';

const getActiveSearchGenre = ({
  genreCode,
  data,
}: {
  genreCode: string;
  data?: cosmo_getSearchGenresQuery;
}):
  | cosmo_getSearchGenresQuery['webfront_searchGenres'][number]['searchGenres'][number]
  | null => {
  if (data) {
    for (let idx = 0; idx < data.webfront_searchGenres.length; idx++) {
      const activeSearchGenre = data.webfront_searchGenres[
        idx
      ].searchGenres.find((searchGenre) => searchGenre.id === genreCode);
      if (activeSearchGenre) {
        return activeSearchGenre;
      }
    }
  }
  return null;
};

const Layout = styled.div`
  margin-top: 16px;

  @media ${DEVICE.mobileWide} {
    margin-top: 14px;
  }
`;

const Row = styled.div`
  display: flex;
  padding: 0 ${BLOCK_PADDING}px;

  &:not(:first-child) {
    margin-top: 8px;
  }

  margin-left: -6px;
  > * {
    margin-left: 6px;
  }

  @media ${DEVICE.mobileWide} {
    padding: 0 ${BLOCK_PADDING_MOBILE}px;
  }
`;

const CategoryButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  height: 40px;
  padding: 0px 10px 0px 16px;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.button.category.border};
  backdrop-filter: blur(10px);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 14px;
  font-weight: 300;
  line-height: 140%;
  color: ${({ theme }) => theme.button.category.text};

  transition: all 0.15s ease-in;

  > div {
    /* Icon style */
    width: 20px;
  }

  &:hover,
  &:focus {
    border: 1px solid ${({ theme }) => theme.button.category.borderHover};
    background-color: ${({ theme }) => theme.button.category.backgroundHover};
  }
`;

type CommonProps = {
  genreCode: string;
};

type CategoryProps = { id: string; name: string; linkProps: LinkProps };

const CategoryList: FC<{
  categories: CategoryProps[];
}> = ({ categories }) => {
  // カテゴリが8個以上の場合は2行にする
  // https://wiki.unext-info.jp/pages/viewpage.action?spaceKey=PD&title=Sub+Category+Block
  const isTwoLines = categories.length >= 8;
  const rows = categories
    .reduce(
      (prev: (typeof categories)[], cur, curIndex) => {
        if (isTwoLines && curIndex % 2 === 1) {
          prev[1].push(cur);
        } else {
          prev[0].push(cur);
        }
        return prev;
      },
      [[], []]
    )
    .filter((array) => array.length);

  return (
    <Layout>
      <CategoryListSlider>
        {rows.map((row, index) => (
          <Row key={index}>
            {row.map((category, index) => (
              <Link
                key={category.id}
                legacyBehavior={false}
                role="button"
                tabIndex={index}
                {...category.linkProps}
              >
                <CategoryButton>
                  <span>{category.name}</span>
                  <Icon name={Icons.HALF_ARROW_RIGHT} />
                </CategoryButton>
              </Link>
            ))}
          </Row>
        ))}
      </CategoryListSlider>
    </Layout>
  );
};

export const LabelCategoryList: FC<CommonProps> = ({ genreCode }) => {
  const { data: labelData } =
    useClientQuery<cosmo_all_labelQuery>(GET_ALL_LABEL);

  const searchGenre = labelData?.labelBlock.labelList.find(
    (label) => label.searchMenuList[0]?.parentMenuCode === genreCode
  );
  if (!searchGenre) {
    return null;
  }

  const menuTypeCode = searchGenre.searchMenuList[0].menuTypeCode;
  const categories: CategoryProps[] = searchGenre.searchMenuList.map(
    (menu) => ({
      id: menu.id,
      name: menu.name,
      linkProps: {
        href: {
          pathname: `${
            GenreUrlPrefix[menuTypeCode as keyof typeof GenreUrlPrefix]
          }/browse/genre/${genreCode}/${menu.id}`,
          query: { [UrlQueryParams.LABEL]: 1 },
        },
      },
    })
  );

  return <CategoryList categories={categories} />;
};

export const VideoCategoryList: FC<CommonProps> = ({ genreCode }) => {
  const { data } =
    useClientQuery<cosmo_getSearchGenresQuery>(GET_SEARCH_GENRES);

  const searchGenre = getActiveSearchGenre({
    genreCode,
    data,
  });
  if (!searchGenre) {
    return null;
  }

  const categories: CategoryProps[] = searchGenre.searchCategories.map(
    (cat) => ({
      id: cat.id,
      name: cat.name,
      linkProps: {
        href: {
          pathname: `/browse/genre/${genreCode}/${cat.id}`,
        },
      },
    })
  );

  return <CategoryList categories={categories} />;
};

export const BookCategoryList: FC<CommonProps> = ({ genreCode }) => {
  const { data: bookCategoryBlockData } = useClientQuery<
    cosmo_getBookCategoryBlockQuery,
    cosmo_getBookCategoryBlockQueryVariables
  >(GET_BOOK_CATEGORY_BLOCK, {
    variables: { genreId: genreCode },
  });

  if (!bookCategoryBlockData?.bookSearchCategoryBlock) {
    return null;
  }

  const categories: CategoryProps[] =
    bookCategoryBlockData.bookSearchCategoryBlock.menuList.map(
      (menu, index) => ({
        id: menu.code,
        name: menu.name,
        linkProps: {
          href: {
            pathname: `/book/browse/genre/${genreCode}/${menu.code}`,
          },
          onClick: () => {
            getKafkaClient().trackUserClickDimension1<BookGenreTopBookCategorySelectorGenreButtonLog>(
              BOOK_CATEGORY_SELECTOR_GENRE_BUTTON,
              {
                category_code: menu.code,
                genre_code: genreCode,
                index,
                leanback_genre_code: genreCode,
              }
            );
          },
        },
      })
    );

  return <CategoryList categories={categories} />;
};
