import styled from 'styled-components';
import { default as SideButton } from '../../shared/components/Common/SideButton';
import {
  BLOCK_PADDING,
  BLOCK_PADDING_MOBILE,
} from '../../shared/components/Slider';
import { DEVICE } from '../../shared/styles';
import { withProperties } from '../../utils/typeHelper';

interface BlockTitleProps {
  hasNoLead?: boolean;
}

const BlockTitle = styled.p<BlockTitleProps>`
  font-size: 18px;
  line-height: 1;
  font-weight: 600;
  margin: 0 0 ${({ hasNoLead }) => (hasNoLead ? 24 : 16)}px 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  overflow: hidden;
  display: inline-flex;
  line-height: 1;

  @media ${DEVICE.mobileWide} {
    font-size: 16px;
    margin: 0 0 ${({ hasNoLead }) => (hasNoLead ? 16 : 8)}px 15px;
  }
`;

const Lead = styled.h3`
  font-size: 14px;
  font-weight: 300;
  color: ${({ theme }) => theme.genre.leadText};
  padding: 0 24px 0 ${BLOCK_PADDING}px;
  margin: 0 0 24px;
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1;

  @media ${DEVICE.mobileWide} {
    font-size: 12px;
    padding: 0 16px 0 ${BLOCK_PADDING_MOBILE}px;
    margin: 0 0 16px;
    white-space: initial;
    line-height: 1.5;
  }
`;

interface StyledLinkProps extends BlockTitleProps {
  as?: 'div';
}

const StyledLink = styled.a<StyledLinkProps>`
  display: inline-flex;
  align-items: center;
  flex-flow: row nowrap;
  cursor: ${({ as }) => (as === 'div' ? 'initial' : 'pointer')};
  box-sizing: border-box;
  padding: 0 24px 0 ${BLOCK_PADDING}px;
  margin-bottom: ${({ hasNoLead }) => (hasNoLead ? 24 : 16)}px;
  line-height: 1.2;
  max-width: 100%;

  @media ${DEVICE.mobileWide} {
    padding: 0 16px 0 ${BLOCK_PADDING_MOBILE}px;
    margin-bottom: ${({ hasNoLead }) => (hasNoLead ? 16 : 8)}px;
    width: 100%;
  }
`;

const LinkLogo = styled.div`
  display: flex;
  svg {
    height: 16px;
  }
`;

const LinkText = styled.p`
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0;
  font-feature-settings: 'halt';

  :not(:only-child) {
    margin-right: 14px;
  }

  @media ${DEVICE.mobileWide} {
    margin-right: 14px;
  }
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;

  @media ${DEVICE.mobileWide} {
    margin-left: auto;
  }
`;

const TitleNumber = styled.span`
  color: ${({ theme }) => theme.genre.titleNumber};
  font-size: 16px;
  word-break: keep-all;

  @media ${DEVICE.mobileWide} {
    display: none;
  }
`;

const ShowAllText = styled.span`
  display: none;

  @media ${DEVICE.mobileWide} {
    display: block;
    color: ${({ theme }) => theme.genre.titleNumber};
    font-size: 14px;
    word-break: keep-all;
  }
`;

const LinkButton = styled(SideButton)`
  &&& {
    margin-left: 8px;

    ${StyledLink}:hover & {
      div {
        opacity: 1;
      }
    }
    ${StyledLink}:focus & {
      div {
        opacity: 1;
      }
    }

    svg {
      top: 0;
    }
  }
`;

export default withProperties(BlockTitle, {
  Lead,
  StyledLink,
  LinkLogo,
  LinkText,
  RightContainer,
  LinkButton,
  TitleNumber,
  ShowAllText,
});
