import { useRouter } from 'next/router';
import { useContext } from 'react';
import { getGenreDisplayCodeByName } from '../../utils';
import { LeanbackContext } from '../components/Leanback/LeanbackContext';

/**
 * Gets genre information from router query.
 */
const useGenreQuery = (
  genreQueryParam = 'genre'
): {
  genreName: string;
  genreQuery: string;
  genreDisplayCode: string;
  genreId?: string;
} => {
  const router = useRouter();
  const query = router.query?.[genreQueryParam];
  const genreQuery = Array.isArray(query) ? query[0] : query || '';

  const genreDisplayCode = getGenreDisplayCodeByName(genreQuery) || '';

  const { leanbackData, getLeanbackByDisplayCode } =
    useContext(LeanbackContext);
  const genreName = genreDisplayCode
    ? getLeanbackByDisplayCode(leanbackData, genreDisplayCode).name
    : '';
  const genreId = genreDisplayCode
    ? getLeanbackByDisplayCode(leanbackData, genreDisplayCode).id
    : undefined;

  return { genreName, genreQuery, genreDisplayCode, genreId };
};

export default useGenreQuery;
