/**
 *  @see https://wiki.unext-info.jp/pages/viewpage.action?pageId=57624701
 */

// Show more
export const NEW_ARRIVAL_BLOCK_MORE = 'bookGenreTop-newArrivalBlock-more';
export const RANKING_BLOCK_MORE = 'bookGenreTop-rankingBlock-more';
export const FREE_BLOCK_MORE = 'bookGenreTop-freeBlock-more';
export const ORIGINAL_BLOCK_MORE = 'bookGenreTop-originalBlock-more';
export const ALT_BLOCK_MORE = 'bookGenreTop-altBlock-more';
export const PREORDER_BLOCK_MORE = 'bookGenreTop-preorderBlock-more';
export const CAMPAIGN_BLOCK_MORE = 'bookGenreTop-campaignBlock-more';
export const BOOK_RECOMMEND_BLOCK_MORE = 'bookGenreTop-recommendBlock-more';
export const GROUP_ALT_BLOCK_MORE = 'bookGenreTop-groupAltBlock-more';

export const ALT_BLOCK_MORE_CARD = 'bookGenreTop-altBlock-moreCard';

export const BANNER_BLOCK_BANNER = 'bookGenreTop-bannerBlock-banner';

export const SPOTLIGHT_BANNER = 'bookGenreTop-spotlight-banner';

export const BOOK_CATEGORY_SELECTOR_GENRE_BUTTON =
  'bookGenreTop-bookCategorySelector-genreButton';

// Slider and titleDetail
export const NEW_ARRIVAL_BLOCK_PAGER_PREV =
  'bookGenreTop-newArrivalBlock-pagerPrev';
export const NEW_ARRIVAL_BLOCK_PAGER_NEXT =
  'bookGenreTop-newArrivalBlock-pagerNext';
export const NEW_ARRIVAL_BLOCK_TITLE_CARD =
  'bookGenreTop-newArrivalBlock-titleCard';
export const RANKING_BLOCK_PAGER_PREV = 'bookGenreTop-rankingBlock-pagerPrev';
export const RANKING_BLOCK_PAGER_NEXT = 'bookGenreTop-rankingBlock-pagerNext';
export const RANKING_BLOCK_TITLE_CARD = 'bookGenreTop-rankingBlock-titleCard';
export const FREE_BLOCK_PAGER_PREV = 'bookGenreTop-freeBlock-pagerPrev';
export const FREE_BLOCK_PAGER_NEXT = 'bookGenreTop-freeBlock-pagerNext';
export const FREE_BLOCK_TITLE_CARD = 'bookGenreTop-freeBlock-titleCard';
export const ALT_BLOCK_PAGER_PREV = 'bookGenreTop-altBlock-pagerPrev';
export const ALT_BLOCK_PAGER_NEXT = 'bookGenreTop-altBlock-pagerNext';
export const ALT_BLOCK_TITLE_CARD = 'bookGenreTop-altBlock-titleCard';
export const ORIGINAL_BLOCK_PAGER_PREV = 'bookGenreTop-originalBlock-pagerPrev';
export const ORIGINAL_BLOCK_PAGER_NEXT = 'bookGenreTop-originalBlock-pagerNext';
export const ORIGINAL_BLOCK_TITLE_CARD = 'bookGenreTop-originalBlock-titleCard';
export const PREORDER_BLOCK_PAGER_PREV = 'bookGenreTop-preorderBlock-pagerPrev';
export const PREORDER_BLOCK_PAGER_NEXT = 'bookGenreTop-preorderBlock-pagerNext';
export const PREORDER_BLOCK_TITLE_CARD = 'bookGenreTop-preorderBlock-titleCard';
export const CAMPAIGN_BLOCK_PAGER_PREV = 'bookGenreTop-campaignBlock-pagerPrev';
export const CAMPAIGN_BLOCK_PAGER_NEXT = 'bookGenreTop-campaignBlock-pagerNext';
export const CAMPAIGN_BLOCK_TITLE_CARD = 'bookGenreTop-campaignBlock-titleCard';
export const BOOK_RECOMMEND_BLOCK_TITLE_CARD =
  'bookGenreTop-recommendBlock-titleCard';
export const GROUP_ALT_BLOCK_GROUP_TAB = 'bookGenreTop-groupAltBlock-groupTab';
export const GROUP_ALT_BLOCK_MORE_CARD = 'bookGenreTop-groupAltBlock-moreCard';
export const GROUP_ALT_BLOCK_TITLE_CARD =
  'bookGenreTop-groupAltBlock-titleCard';
