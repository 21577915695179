import { useContext } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { SmallBannerContext, SmallBannerOptions } from './SmallBannerContext';

const useSmallBanner = (options: SmallBannerOptions): void => {
  const bannerContext = useContext(SmallBannerContext);
  useDeepCompareEffect(() => {
    bannerContext.updateOptions(options);

    return () => {
      bannerContext.updateOptions({ triggerHeight: 1 });
    };
  }, [options]);
};

export default useSmallBanner;
