import styled from 'styled-components';
import { DEVICE } from '../../shared/styles';

export const BlocksContainer = styled.div`
  margin-top: 56px;

  @media ${DEVICE.mobileWide} {
    margin-top: 40px;
  }
`;
const GenreContainer = styled.div`
  overflow-x: hidden;
  padding-bottom: 100px;
`;

export default GenreContainer;
