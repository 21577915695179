import Link, { LinkProps } from 'next/link';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { globalMessages } from '../../shared/constants/messages';
import BlockTitle from './BlockTitle';

interface BlockHeadingProps {
  title: string;
  numText?: string;
  hasNoLead?: boolean;
}

const BlockHeading: React.FC<BlockHeadingProps & LinkProps> = ({
  title,
  numText,
  onClick,
  hasNoLead = true,
  ...linkProps
}) => {
  return (
    <Link {...linkProps} passHref legacyBehavior>
      <BlockTitle.StyledLink
        data-ucn="blockHeading-more-link"
        onClick={onClick}
        hasNoLead={hasNoLead}
      >
        <BlockTitle.LinkText>{title}</BlockTitle.LinkText>
        <BlockTitle.RightContainer>
          {numText && (
            <BlockTitle.TitleNumber>{numText}</BlockTitle.TitleNumber>
          )}
          <BlockTitle.ShowAllText>
            <FormattedMessage {...globalMessages.more} />
          </BlockTitle.ShowAllText>
          <BlockTitle.LinkButton />
        </BlockTitle.RightContainer>
      </BlockTitle.StyledLink>
    </Link>
  );
};

export default BlockHeading;
