import React from 'react';
import styled from 'styled-components';
import Placeholder, {
  placeholderStyle,
} from '../../shared/components/Common/Placeholder';
import Slider, {
  BLOCK_PADDING,
  BLOCK_PADDING_MOBILE,
} from '../../shared/components/Slider';
import { DEVICE } from '../../shared/styles';
import {
  BLOCKS_MARGIN,
  BLOCKS_MARGIN_MOBILE,
  HEADING_MARGIN_BOTTOM,
  HEADING_MARGIN_BOTTOM_MOBILE,
} from './constants';

const PlaceholderContainer = styled.div`
  width: 100%;
  margin-bottom: ${BLOCKS_MARGIN}px;
  @media ${DEVICE.mobileWide} {
    margin-bottom: ${BLOCKS_MARGIN_MOBILE}px;
  }
`;

const Heading = styled.div`
  position: relative;
  display: flex;
  gap: 12px;
  margin: 0 ${BLOCK_PADDING}px ${HEADING_MARGIN_BOTTOM}px;

  @media ${DEVICE.mobileWide} {
    margin: 0 ${BLOCK_PADDING_MOBILE}px ${HEADING_MARGIN_BOTTOM_MOBILE}px;
  }
`;

const Title = styled.div`
  ${placeholderStyle};
  width: 198px;
  height: 28px;
  @media ${DEVICE.mobileWide} {
    width: 132px;
    height: 22px;
  }
`;

const Link = styled.div`
  ${placeholderStyle};
  position: relative;
  width: 20px;
  height: 20px;
  align-self: flex-end;
  border-radius: 4px;

  @media ${DEVICE.mobileWide} {
    margin-left: auto;
    width: 18px;
    height: 18px;
    align-self: center;
    &:after {
      content: '';
      ${placeholderStyle};
      position: absolute;
      left: -76px;
      bottom: 0;
      width: 70px;
      height: 18px;
    }
  }
`;

interface SliderPlaceholderProps {
  type?: 'video' | 'book';
}

const SliderPlaceholder: React.FC<SliderPlaceholderProps> = ({
  type = 'video',
}) => {
  return (
    <PlaceholderContainer>
      <Heading>
        <Title />
        <Link />
      </Heading>
      <Slider
        type={type}
        keyPrefix="fakeprefix"
        items={() =>
          [...Array(15)].map((_i, index) => (
            <Placeholder.BlockViewTitleCard
              key={`placeholder-item-${index}`}
              contentType={type}
            />
          ))
        }
      />
    </PlaceholderContainer>
  );
};

export default React.memo(SliderPlaceholder);
