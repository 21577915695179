import { gql } from '@apollo/client';

// Fragment used for character cards
export const characterCardFragment = gql`
  fragment CharacterCard on SakuhinGroup {
    code
    name
    thumbnail {
      circle
    }
  }
`;
